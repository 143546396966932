import React, {useMemo, useState ,useCallback} from "react";
import MaterialReactTable from "material-react-table";
import { IconButton, Tooltip,Button,MenuItem } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {Edit} from "@mui/icons-material";
import {useQuery } from "@tanstack/react-query";
import agent from "api/agent";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";

import getUserRoleFromToken from "utils/getUserRoleFromToken";


const Example = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // Notification  Sb
  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const [errorSB, setErrorSB] = useState(false);

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "api/admin/UserManagement/Managers",
        "https://beautyindustry.ibu.az"
      );
      fetchURL.searchParams.set(
        'start',
        `${pagination.pageIndex + 1}`,
      );
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response =  await axios.get(fetchURL.href , {
        headers: {
          "ngrok-skip-browser-warning": "any",
        }
      });
      return response.data;
    }
  });

          

  const columns = useMemo(
    () => [
      {
        accessorKey: "nameSurname",
        header: "Ad Soyad",
        id: "NameSurname",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          error: !!validationErrors.NameSurname, //highlight mui text field red error color
          helperText: validationErrors.NameSurname, //show error message in helper text.
          required: true,
          type: "string",
          onChange: (event) => {
            const value = event.target.value;
            if (!value) {
              setValidationErrors((prev) => ({ ...prev, NameSurname: "Ad Soyad boş ola bilməz" }));
            } else {
              delete validationErrors.NameSurname;
              setValidationErrors({ ...validationErrors });
            }
          },
        }),
      },
      {
        accessorKey: "userName",
        header: "Istifaçı adı",
        id: "UserName",
        muiTableBodyCellEditTextFieldProps: {
          error: !!validationErrors.UserName, //highlight mui text field red error color
          helperText: validationErrors.UserName, //show error message in helper text.
          required: true,
          type: "string",
          onChange: (event) => {
            const value = event.target.value;
            if (!value) {
              setValidationErrors((prev) => ({ ...prev, UserName: "İstifadəçi adı boş ola bilməz" }));
            } else {
              delete validationErrors.UserName;
              setValidationErrors({ ...validationErrors });
            }
          }
        },
      },
       {
        accessorKey: "commissionRate",
        header: "Faiz",
        id: "CommissionRate",
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          error: !!validationErrors.CommissionRate, //highlight mui text field red error color
          helperText: validationErrors.CommissionRate, //show error message in helper text.
          required: true,
          type: "number",
          onChange: (event) => {
            const value = event.target.value;
            if (!value) {
              setValidationErrors((prev) => ({ ...prev, CommissionRate: "Faiz boş ola bilməz" }));
            } else {
              delete validationErrors.CommissionRate;
              setValidationErrors({ ...validationErrors });
            }
          },
          children: [
            <MenuItem key={0} value={0}>
              0%
            </MenuItem>,
            <MenuItem key={5}  value={5}>
              5%
            </MenuItem>,
            <MenuItem key={8} value={8}>
              8%
            </MenuItem>,
            <MenuItem key={10} value={10}>
              10%
            </MenuItem>,
          ],
        }
      },
      {
        accessorKey: "password",
        header: "Şifrə",
        muiTableBodyCellEditTextFieldProps: {
          error: !!validationErrors.password, //highlight mui text field red error color
          helperText: validationErrors.password, //show error message in helper text.
          type: "string",
          onChange: (event) => {
            const value = event.target.value;
            if (value?.length > 0) {
              if (!passwordRegex.test(value)) {
                setValidationErrors((prev) => ({ ...prev, password: "Şifrə ən az 6 simvoldan ibarət olmalıdır və ən az 1 böyük hərf, 1 kiçik hərf, 1 rəqəm və 1 xüsusi simvol daxil etməlisiniz" }));
              } else {
                delete validationErrors.password;
                setValidationErrors({ ...validationErrors });
              }
            } else {
                delete validationErrors.password;
                setValidationErrors({ ...validationErrors });
            }
          }
        },
      },
    ],
    [validationErrors]
  );

  // password regex . Password must be 1 uppercase character, 1 lowercase character, 1 digit, 1 special character and 8 characters long
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
        setLoading(true);
        const response = await agent.User.update({
          userId:  row.original.id,
          userName: values.UserName,
          nameSurname: values.NameSurname,
          password: values.password,
          commissionRate: values.CommissionRate,
        });
        if (response?.succeeded) {
        setErrorSB(false);
        setSuccessSB(true);
        setLoading(false);
        exitEditingMode();
        refetch();
        data[row.index] = values;
        } else {
          setLoading(false);
          setSuccessSB(true);
          setErrorSB(true);
        }
      }
  };
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const rowsPerPageOptions = [];
for (let i = 10; i <= data?.totalCount; i += 10) {
  rowsPerPageOptions.push(i);
}
rowsPerPageOptions.push(data?.totalCount);

  return (
    <>
    <MaterialReactTable
      columns={columns}
      data={data?.items ?? []} //data is undefined on first render
      initialState={{ columnVisibility: { password: false } }}
      manualFiltering
      manualSorting
      enableRowActions
      manualPagination
      enableRowSelection
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      enableHiding={true}
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      editingMode="modal" //default
      enableEditing
      onEditingRowSave={handleSaveRowEdits}
      onEditingRowCancel={handleCancelRowEdits}
      
      muiTablePaginationProps={{
        rowsPerPageOptions: rowsPerPageOptions,
        showFirstButton: true,
        showLastButton: true,
      }}
      renderRowActions={({ row, table }) => (
          <IconButton onClick={() => table.setEditingRow(row)}>
              <Edit
                sx={{
                  color: "orange",
                }}
              />
         </IconButton>
         
      )}
      renderTopToolbarCustomActions={({ table }) => {
        const handleDeactivate = async () => {
          let selected = table.getSelectedRowModel().flatRows.map((row) => {
            return row.original.id;
          });
            await agent.Managers.delete(selected);
        };

        return (
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <Button
              color="error"
              disabled={!table.getIsSomeRowsSelected()}
              onClick={handleDeactivate}
              variant="contained"
            >
              Silmək
            </Button>
            <Tooltip arrow title="Refresh Data">
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      }}
      rowCount={data?.totalCount ?? 0}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isFetching || loading,
        sorting,
      }}
    />
    <MDSnackbar
    color={errorSB ? "error" : "success"}
    icon="check"
    title={errorSB ? "Xəta baş verdi" : "Uğurlu əməliyyat"}
    content={errorSB ? "Əməliyyatı yenidən yoxlayın" : "Əməliyyat uğurla yerinə yetirildi"}
    dateTime={new Date().toLocaleString().split(",")[0]}
    open={successSB}
    onClose={closeSuccessSB}
    close={closeSuccessSB}
    bgWhite
  />
  {loading && (
     <LinearProgress
         hidden={!loading}
      />
  )}
  </>
  );
};

const Managers = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Menecerlər
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              {
               getUserRoleFromToken() === "Administrator" || "Admin"  ? <Example /> : <h1>Yalnız Administratorlər bu səhifəyə baxa bilər</h1>
              }
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Managers;
