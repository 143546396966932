import React, { useMemo, useState} from "react";
import MaterialReactTable from "material-react-table";
import {
  IconButton,
  Tooltip,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQuery } from "@tanstack/react-query";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SelectBox from "components/MDSelectBox";

// Agent for http requests
import axios from "axios";

import agent from "api/agent";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";

const Example = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);


  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "api/admin/Product/productInventory",
        "https://beautyindustry.ibu.az"
      );
      fetchURL.searchParams.set(
        'start',
        `${pagination.pageIndex + 1}`,
      );
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response = await axios.get(fetchURL.href, {
        headers: {
          "ngrok-skip-browser-warning": "any",
        },
      });
      return response.data;
    },
    keepPreviousData: true,
  });

  const handleFilterForManager = (managerId) => {
    if (managerId === "all") {
      setColumnFilters((prev) => {
        const newColumnFilters = prev.filter(
          (columnFilter) => columnFilter.id !== "manager"
        );
        return newColumnFilters;
      });
    } else {
      setColumnFilters((prev) => {
        const newColumnFilters = prev.filter(
          (columnFilter) => columnFilter.id !== "manager"
        );
        newColumnFilters.push({
          id: "manager",
          value: managerId,
        });
        return newColumnFilters;
      });
    }
  };

  const [managers, setManagers] = useState([]);
  React.useEffect(() => {
    agent.Managers.allmanagers().then((response) => {
      const managers = response?.map((manager) => {
        return {
          value: manager.id,
          label: manager.userName,
        };
      });
      setManagers([
        {
          value: "all",
          label: "Hamısı",
        },
        ...managers,
      ]);
    });
  }, []);

  const getUniqueManagers = () => {
    const uniqueManagers = new Set();
    data?.items.forEach((item) => {
      Object.keys(item.managerQuantities).forEach((manager) => {
        uniqueManagers.add(manager);
      });
    });
    return Array.from(uniqueManagers);
  };

  const columns = useMemo(() => {
    const uniqueManagers = getUniqueManagers();
    const dynamicColumns = uniqueManagers.map((manager) => ({
      header: manager,
      accessorKey: manager,
      manualFiltering: true,
      Filter: () => null,
      enableSorting: false,
      id: manager,
      Cell: ({ row }) => {
        const managerQuantity = row.original.managerQuantities[manager];
        return (
          <MDBox
            component="span"
            sx={{
              display: "inline-block",
              testAlign: "center",
              borderRadius: "4px",
              padding: "0.25rem 0.5rem",
              backgroundColor: managerQuantity > 0 ? "success.main" : "error.main",
              color: "white",
            }}
          >
            {managerQuantity}
          </MDBox>
        );
      },
      minSize: 20,
      maxSize: 20,
      size: 2,
    }));
  
    return [
      {
        header: "Məhsul",
        accessorKey: "name",
        id: "Name",
        minSize: 20,
        maxSize: 20,
        size: 2,
      },
      ...dynamicColumns,
      {
        header: "Cəm",
        accessorKey: "totalQuantity",
        manualFiltering: true,
        Filter: () => null,
        enableSorting: false,
        id: "Total",
        minSize: 20,
        maxSize: 20,
        size: 2,
      },
      {
        header: "Anbarda",
        accessorKey: "quantity",
        manualFiltering: true,
        Filter: () => null,
        enableSorting: false,
        id: "Quantity",
        minSize: 20,
        maxSize: 20,
        size: 2,
      },
    ];
  }, [data]);




const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

const rowsPerPageOptions = [];
for (let i = 10; i <= data?.totalCount; i += 10) {
  rowsPerPageOptions.push(i);
}
rowsPerPageOptions.push(data?.totalCount);

const handleExportRows = (rows) => {
  // Yeniden düzenlenmiş satırları tutacak bir dizi oluştur
  const formattedRows = rows.map(row => {
    // Her bir satırın yöneticilere göre miktarlarını tutacak bir nesne oluştur
    const managerQuantities = {};
    // Eğer satırın managerQuantities özelliği varsa, direkt olarak kullan
    if (row.original.managerQuantities) {
      Object.assign(managerQuantities, row.original.managerQuantities);
    } else {
      // Eğer managerQuantities özelliği yoksa, tüm yöneticilerin miktarlarını sırasıyla al
      const uniqueManagers = getUniqueManagers();
      uniqueManagers.forEach(manager => {
        managerQuantities[manager] = row.original[manager] || 0;
      });
    }
    // Yeniden düzenlenmiş satırı oluştur ve döndür
    return {
      name: row.original.name,
      totalQuantity: row.original.totalQuantity,
      quantity: row.original.quantity,
      ...managerQuantities
    };
  });

  // CSV seçeneklerini ayarla
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true // Kolon başlıklarını kullan
  };

  // CSV'yi oluştur ve indir
  const csvExporter = new ExportToCsv(csvOptions);
  csvExporter.generateCsv(formattedRows);
};



  return (
    
    <>
      <MaterialReactTable
        columns={columns}
        data={data?.items ?? []} //data is undefined on first render
        initialState={{ showColumnFilters: true }}
        manualFiltering
        manualPagination
        manualSorting
        enableColumnResizing
        editingMode="modal" //default
        onEditingRowCancel={handleCancelRowEdits}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        muiTablePaginationProps={{
          rowsPerPageOptions: rowsPerPageOptions,
          showFirstButton: true,
          showLastButton: true,
        }}
        renderTopToolbarCustomActions={({ table }) => {
           return (
            <>
        <MDBox
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          <MDButton
            color="primary"
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Data
          </MDButton>
          </MDBox>
              <div style={{ display: "flex", alignItems: "center" }}>
                <SelectBox
                  style={{ width: "200px" }}
                  label="Manager"
                  options={managers}
                  onChange={(event) => {
                    handleFilterForManager(event);
                  }}
                />
               <Tooltip arrow title="Refresh Data">
                  <IconButton onClick={() => refetch()}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          );
        }}
        rowCount={data?.totalCount ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
    </>
  );
};



const ProductInventory = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Məhsulların Anbardakı Qalığı
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default ProductInventory;
