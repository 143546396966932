import React, { useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { IconButton, Tooltip} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQuery } from "@tanstack/react-query";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// 
import dayjs, { Dayjs } from 'dayjs';

import getUserRoleFromToken from "utils/getUserRoleFromToken";

import Autocomplete from "@mui/material/Autocomplete";


// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
import agent from "api/agent";


// Ibeauty Admin Dashboard React component
import MDInput from "components/MDInput";
import { Delete } from "@mui/icons-material";
import MDButton from "components/MDButton";

const Example = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [totalDebt, setTotalDebt] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  React.useEffect(() => {
      if(columnFilters.length === 0){
        setColumnFilters([
          {
            id:"Created",
            value: [dayjs(new Date()).toISOString(), dayjs(new Date()).toISOString()]
          }
        ])
      }
  }, [])

  const { data, isError, isFetching, isLoading, refetch} = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
         "api/admin/Report/financial-sales-report",
         "https://beautyindustry.ibu.az"
       );
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      const response = await axios.get(fetchURL.href, {
         headers: {
           "ngrok-skip-browser-warning": "any",
         },
       });
       return response?.data ?? [];
    },
  }
  );

  React.useEffect(() => {
    const customerFirstDebts = {};
    let calculatedTotalDebt = 0;

    data?.forEach(item => {
        if (!customerFirstDebts[item.customer]) {
            customerFirstDebts[item.customer] = item.debt;
            calculatedTotalDebt += item.debt;
        }
    });
    setTotalDebt(calculatedTotalDebt);
    setTotalPayment(data?.reduce((acc, item) => acc + item.payment, 0));
    setTotalAmount(data?.reduce((acc, item) => acc + item.total, 0));
}, [data]);




  const columns = useMemo(
    () => [
      {
        header: "Sənədin adı",
        accessorKey: "document",
        Filter: () => (
          <Autocomplete
            options={[
              "Kassa Mədaxil",
              "Satış",
              "Vozvrat",
            ]}
            onChange={(e, value) => {
               if(value === "Kassa Mədaxil"){
                 setColumnFilters([
                  ...columnFilters,
                   {
                     id: "IsDebtPayment",
                     value: true,
                   },
                 ]);
                }
                else if(value === "Satış"){
                  setColumnFilters([
                    ...columnFilters,
                    {
                      id: "IsDebtPayment",
                      value: false,
                    },
                    {
                      id: "IsReturn",
                      value: false,
                    }
                  ]);
                }
                else if(value === "Vozvrat"){
                  setColumnFilters([
                    ...columnFilters,
                    {
                      id: "IsReturn",
                      value: true,
                    },
                  ]);
                }else {
                  setColumnFilters(
                    columnFilters.filter((filter) => filter.id !== "IsDebtPayment" && filter.id !== "IsReturn")
                  );
                }
            }}
            renderInput={(params) => (
              <MDInput
                {...params}
                label="Sənədin adı"
                variant="standard"
                sx={{ minWidth: "120px" }}
              />
            )}
          />
        ),
        enableSorting: false,
        id: "Document"
      },
      {
        accessorFn: (row) => new Date(row.created), //convert to Date for sorting and filtering
        id: "Created",
        header: "Tarix",
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        
        Filter: ({ column }) => {
          const [startDate, setStartDate] = React.useState(
           new Date()
          );
          const [endDate, setEndDate] = React.useState(
            new Date()
          );
        
          const handleDateChange = (newStartDate, newEndDate) => {
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        
            const newValue = [newStartDate.toISOString(), newEndDate.toISOString()];
            setColumnFilters([
              ...columnFilters.filter((filter) => filter.id !== column.id),
              { id: 'Created', value: newValue }
            ]);
          };
        
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div>
                <DatePicker
                  label="Başlangıç tarihi"
                  onChange={(newValue) => handleDateChange(newValue, endDate)}
                  value={dayjs(startDate)}
                />
                <DatePicker
                  label="Bitiş tarihi"
                  onChange={(newValue) => handleDateChange(startDate, newValue)}
                  value={dayjs(endDate)}
                />
              </div>
            </LocalizationProvider>
          );
        },
        
      },
      {
        header: "Menecer",
        accessorKey: "manager",
        id: "Sale.Customer.Manager.NameSurname",
      },
      {
        header: "Müştəri",
        accessorKey: "customer",
        id: "Sale.Customer.NameSurname",
      },
      {
        header: "Məhsulun adı",
        accessorKey: "product",
        id: "Sale.Product.Name",
      },
      {
        header: "Miqdar",
        accessorKey: "quantity",
        id: "Sale.Quantity", 
      },
      {
        accessorKey: "price",
        header: "Qiymət",
        Cell: ({ cell }) => cell.getValue()?.toFixed(2),
        Filter: () => null,
        enableSorting: false, 
        id: "Price",
      },
      {
        accessorKey: "total",
        header: "Cəm",
        Cell: ({ cell }) => cell.getValue()?.toFixed(2),
        Filter: () => null,
        enableSorting: false, 
        id: "Total",
      },
      {
        accessorKey: "payment",
        header: "Ödəniş",
        Cell: ({ cell }) => cell.getValue()?.toFixed(2),
        id: "Amount",
      },
      {
        accessorKey: "debt",
        header: "Borc",
        Cell: ({ cell }) => cell.getValue()?.toFixed(2),
        Filter: () => null,
        enableSorting: false, 
        id: "Debt",
      },
    ],
  );
  const [rowSelection, setRowSelection] = useState([]);
  const [fetcLoading, setFetchLoading] = useState();

  React.useEffect(() => {
    setFetchLoading(isFetching);
  }, [isFetching]);

  return (
    <MaterialReactTable
      columns={columns}
      data={data?? []}
      initialState={{ showColumnFilters: true }}
      enablePagination={false}
      manualFiltering
      manualSorting
      enableMultiRowSelection={false} //shows radio buttons instead of checkboxes
      enableRowSelection
      enableStickyFooter
      onRowSelectionChange={setRowSelection}
      getRowId={(row) => row.id}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      renderTopToolbarCustomActions={({ table }) => {
        return (
          <>
            <div style={{ display: "flex", gap: "0.5rem" }}>
            <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                      <RefreshIcon />
                    </IconButton>
            
            </Tooltip>
              <MDButton 
              variant="contained"
              color="error"
              disabled={rowSelection.length === 0}
              startIcon={<Delete />}
              onClick={async () => {
                const saleId = data.filter((item) => rowSelection[item.id] === true).map((item) => item.saleId);
                if(saleId.length > 0){
                try {
                  if (!window.confirm("Satışı silmək istədiyinizə əminsiniz?")) {
                    return;
                  }
                  setFetchLoading(true);
                  await agent.Sale.delete(saleId);
                   await refetch();
                   setFetchLoading(false);
                } catch (error) {
                  setFetchLoading(false);
                }
              }
              }}
              >
                Satışı Sil
              </MDButton>
              <MDButton
                variant="contained"
                disabled={rowSelection.length === 0}
                color="error"
                startIcon={<Delete />}
                onClick={async() => {
                 try {
                  const paymentId = Object.keys(rowSelection).filter((key) => rowSelection[key] === true);
                  if(paymentId.length > 0){
                    if (!window.confirm("Ödənişi silmək istədiyinizə əminsiniz?")) {
                      return;
                    }
                    setFetchLoading(true);
                    await agent.Payment.delete(paymentId);
                    await refetch();
                    setFetchLoading(false);
                  }
                 }
                  catch (error) {
                    setFetchLoading(false);
                  }
                }}
              >
                Ödənişi Sil
              </MDButton>
            <MDBox
                    component="span"
                    sx={{
                      display: "inline-block",
                      testAlign: "center",
                      borderRadius: "4px",
                      padding: "0.25rem 0.5rem",
                      backgroundColor: "error.main",
                      color: "white",
                      }}
                     >
                        <MDTypography
                         variant="h6"
                         fontWeight="medium"
                         fontSize="1rem"
                         color="white"
                        >
                       Ümumi borc:  {totalDebt} AZN
                        </MDTypography>
                </MDBox>
                <MDBox
                    component="span"
                    sx={{
                      display: "inline-block",
                      testAlign: "center",
                      borderRadius: "4px",
                      padding: "0.25rem 0.5rem",
                      backgroundColor: "success.main",
                      color: "white",
                      }}
                     >
                        <MDTypography
                         variant="h6"
                         fontWeight="medium"
                         fontSize="1rem"
                         color="white"
                        >
                        Ümumi ödəniş:  {totalPayment} AZN
                        </MDTypography>
                    </MDBox>
                    <MDBox
                    component="span"
                    sx={{
                      display: "inline-block",
                      testAlign: "center",
                      borderRadius: "4px",
                      padding: "0.25rem 0.5rem",
                      backgroundColor: "success.main",
                      color: "white",
                      }}
                     >
                        <MDTypography
                         variant="h6"
                         fontWeight="medium"
                         fontSize="1rem"
                         color="white"
                        >
                        Satış:  {totalAmount} AZN
                        </MDTypography>
                    </MDBox>
            </div>
          </>
        );
      }}
      onColumnFiltersChange={setColumnFilters}
      onSortingChange={setSorting}
      state={{
        rowSelection,
        columnFilters,
        isLoading,
        showAlertBanner: isError,
        showProgressBars: fetcLoading,
        sorting,
      }}
    />
  );
};

const SalesReport = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                 Satış hesabatı
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
             {
              <Example /> 
             }
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default SalesReport
