import React, { useMemo, useState ,useEffect} from "react";
import MaterialReactTable from "material-react-table";
import { IconButton, Tooltip} from "@mui/material";
import {Delete} from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQuery } from "@tanstack/react-query";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { Formik, Form, Field } from "formik";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import axios from "axios";
import httpAgent from "api/agent"

const Example = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [totalDebt,setTotalDebt] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 200,
  });
  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
       
       const fetchURL = new URL(
         "api/admin/Customer/customers",
         "https://beautyindustry.ibu.az"
       );
       fetchURL.searchParams.set(
        'start',
        `${pagination.pageIndex + 1}`,
      );
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));


      const response = await axios.get(fetchURL.href, {
         headers: {
           "ngrok-skip-browser-warning": "any",
         },
       });
      
       return response?.data;
    },
  });

  useEffect(() => {
    // Manager'a göre filtre yapıldığında toplam borcu hesapla ve güncelle
    const calculateTotalDebtForManager = () => {
      let total = 0;
      data?.items.forEach(item => {
        total += item.totalDebt;
      });
      setTotalDebt(total);
    };
    // Tüm müşterilerin toplam borcunu hesapla ve güncelle
    const calculateTotalDebtForAllCustomers = async () => {
      const totalDebtForAllCustomers = await httpAgent.User.totalDebtForAllCustomer();
      setTotalDebt(totalDebtForAllCustomers);
    };
    if (columnFilters.length === 0) {
      calculateTotalDebtForAllCustomers();
    } else {
      calculateTotalDebtForManager();
    }
  
  }, [data, columnFilters]);
  


  const columns = useMemo(
    () => [
      {
        header: "Adı",
        accessorKey: "nameSurname",
        id: "NameSurname",
      },
      {
        header: "Menecer",
        accessorKey: "manager",
        id: "Manager.NameSurname",
      },
      {
        header: "Borc",
        accessorKey: "totalDebt",
        Cell: ({ cell }) => cell.getValue()?.toFixed(2) ?? 0,
        Filter: () => null,
        enableSorting: false, 
        id: "TotalDebt",
      },
    ],
    []
  );



  const handleDeleteRow = async (row) => {
    try {
        await httpAgent.User.delete(row.original.id);
        refetch();
    } catch (error) {
        console.log(error);
    }
}
const rowsPerPageOptions = [];
for (let i = 10; i <= data?.totalCount; i += 10) {
  rowsPerPageOptions.push(i);
}
rowsPerPageOptions.push(data?.totalCount);

  return (
    <MaterialReactTable
      columns={columns}
      data={data?.items ?? []}
      initialState={{ showColumnFilters: true }}
      manualFiltering
      manualPagination
      manualSorting
      enableRowActions
      enableColumnActions
      muiSelectCheckboxProps={{
        color: "secondary",
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      muiTablePaginationProps={{
        rowsPerPageOptions: rowsPerPageOptions,
        showFirstButton: false,
        showLastButton: false,
      }}
      renderRowActions={({ row, table }) => (
        row.original.date !== "Bu tarixə qədər olan qalıq"  && (
      <MDBox sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip arrow placement="right" title="Sil">
             <IconButton color="error" onClick={() => handleDeleteRow(row)}>
               <Delete />
             </IconButton>
          </Tooltip>
         </MDBox>
        )
    )}
      renderTopToolbarCustomActions={({ table }) => {
        return (
          <div style={{ display: "flex", gap: "3rem" }}>
            <Tooltip arrow title="Refresh Data">
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
              <MDBox
                    component="span"
                    sx={{
                      display: "inline-block",
                      testAlign: "center",
                      borderRadius: "4px",
                      padding: "0.25rem 0.5rem",
                      backgroundColor: "success.main",
                      color: "white",
                      }}
                     >
                        <MDTypography
                         variant="h6"
                         fontWeight="medium"
                         fontSize="1rem"
                         color="white"
                        >
                       Ümumi borc: {totalDebt.toLocaleString("en-US", { style: "currency", currency: "AZN" })}
                        </MDTypography>
                    </MDBox>
            </Tooltip>
                <Formik
                  initialValues={{
                    customer: "",
                  }}
                  onSubmit={async (values , actions) => {
                    actions.setSubmitting(true);
                    try {
                      await httpAgent.User.addCustomer(values);
                      actions.resetForm();
                      actions.setSubmitting(false);
                      refetch();
                    }catch(error){
                      actions.setSubmitting(false);
                    }
                  }}
                >
                  {({ values, 
                    handleChange,
                    handleSubmit,
                    handleBlur ,
                    isSubmitting,
                    }) => (
                    <Form onSubmit={handleSubmit}>
                        <MDBox mb={1}>
                          <Field
                            type="text"
                            name="customer"
                            id = "customer"
                            disabled={isSubmitting}
                            values={values.customer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Müştəri Adı"
                            as={MDInput}
                            variant="standard"
                            fullWidth
                          />
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                           {isSubmitting ? "Gözləyin..." : "Əlavə et"}
                          </MDButton>
                        </MDBox>
                    </Form>
                  )}
                </Formik>
                
          </div>
        );
      }}
      rowCount={data?.totalCount ?? 0}
      state={{
        columnFilters,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isFetching,
        sorting,
      }}
    />
  );
};

const Customers = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                 Müştərilər
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
             {/* {
               getUserRoleFromToken() === "Administrator" ? <Example /> : <h1>Yalnız Administratorlər bu səhifəyə baxa bilər</h1>
             } */}
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Customers;
